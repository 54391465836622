import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout invertNav>
    <SEO title="404: Not found" />

    <div className="text-center" style={{ position: 'relative', top: 150, minHeight: 'calc(100vh - 349px)' }}>
      <h1>Oops! Page not found.</h1>
      <Link to="/" className="button">Check out Chase Chewning</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
